<template>
  <section class="contact social">
    <h2 class="sub-heading sub-heading--white">
      {{ $t("heading.socials_media") }}
    </h2>
    <b-nav>
      <b-nav-item
        :href="item.url"
        v-for="(item, index) in listItems"
        :key="index"
        class="social__icon"
        v-toggleClass="socials[item.icon]"
        target="_blank"
      >
        <i :class="`icon-${item.icon}`"> </i>
      </b-nav-item>
    </b-nav>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Socials",
  data() {
    return {
      toggleClass: false,
      listItems: [],
      socials: {
        facebook: "#3B5998",
        twitter: "#1DA1F2",
        linkedin: "#4875B4",
        instagram: "#800080",
        youtube: "#ff0a00",
        snapchat: "#FFFC00"
      }
    };
  },
  computed: {
    ...mapGetters(["GlobalSettings", "settings"])
  },
  watch: {
    GlobalSettings: {
      handler() {
        this.listItems = this.settings("socials");
      },
      immediate: true
    }
  },
  directives: {
    toggleClass(el, bind) {
      el.children.forEach((el) => {
        el.addEventListener("mouseenter", (event) => {
          event.target.style.background = bind.value;
        });
        el.addEventListener("mouseleave", (event) => {
          event.target.style.background = "";
        });
      });
    }
  }
};
</script>
